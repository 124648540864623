<template>
  <div v-if="chapter.assets.length > 0" class="chapter-overview-container">
    <h2 @click="chapterToggle">
      <i v-if="getDefaultChapterIcon(chapter.name)" class="fas k-asset-icon" :class="getDefaultChapterIcon(chapter.name)"></i>
      <span v-else class="">{{ chapter.number }}.</span> {{ chapter.name }}
      <k-label v-if="chapter.label" :label="chapter.label"></k-label>
      <i class="fas" :class="`${showChapterContent ? 'fa-angle-up' : 'fa-angle-down'}`"></i>
    </h2>
    <ol v-if="showChapterContent">
      <li v-for="(asset, idx) in sortedAssets" :key="`${asset.asset_type}-${asset.id}`">
        <i v-if="!getDefaultChapterIcon(chapter.name)" class="fas k-asset-icon" :class="getAssetIcon(asset.asset_type)"></i>
        <div class="chapter-asset-title">
          {{ asset.number || idx + 1 }}. <span class="asset-names">{{ asset.name || asset.title }}</span>
          <span v-for="group in asset.programme_groups" class="asset-groups badge">{{ group }}</span>
          <div class="asset-description" v-html="$kpurify.sanitise(asset.description)"></div>
        </div>
      </li>
    </ol>
  </div>
</template>

<style scoped>
.chapter-overview-container h2 {
  cursor: pointer;
  align-items: center;
  display: flex;
  gap: 15px;
}

.chapter-overview-container li {
  display: flex;
  gap: 10px;
  margin: 5px 0;
  align-items: baseline;
}

.chapter-overview-container li > i {
  min-width: 25px;
}

.chapter-asset-title {
  gap: 0;
}

.chapter-asset-title span {
  font-size: 1.1em;
  letter-spacing: 0.4px;
  color: var(--kate-type-light);
}

.asset-description {
  font-style: italic;
  color: var(--kate-type-alt);
  margin-bottom: 10px;
}
</style>

<script>
import AssetIconMixin from '@mixins/asset-icon-mixin';
import KLabel from '@base-components/k-label.vue';
import { sortObjectArray } from '@utils/sort-by-object-property';

export default {
  components: {
    KLabel,
  },

  mixins: [AssetIconMixin],

  props: {
    chapter: Object,
  },

  data() {
    return {
      showChapterContent: true,
    };
  },

  computed: {
    sortedAssets() {
      if (this.chapter && this.chapter.assets) {
        return sortObjectArray(this.chapter.assets, 'number');
      }
      return [];
    },
  },

  methods: {
    chapterToggle() {
      this.showChapterContent = !this.showChapterContent;
    },
  },
};
</script>
