<template>
  <div>
    <h1>Calendar Event Overview</h1>
    <asset-overview v-if="ready"
      asset-icon="fa-calendar-alt"
      :assets="sortedCalendarEvents"
      :kable-headers="kableHeaders"
      :kable-rows="kableRows"
      :show-archived-assets="showArchived"
      @tablecallback="tableCallback"
      @archive-toggle="updateArchiveToggle"
    >
      <template #new-asset>
        <add-calendar-events @calendar-event-added="getCalendarEvents"></add-calendar-events>
      </template>
      <template #preview-asset>
        <update-calendar-events :calendarEvent="calendarEventToUpdate"
          @close="closeEditModal"
          @update="getCalendarEvents">
        </update-calendar-events>
      </template>
    </asset-overview>
  </div>
</template>

<script>
import ErrorMixin from '@mixins/error-mixins';
import PageReadyMixin from '@mixins/page-ready-mixin';
import getIcon from '@utils/kate-table-icons';
import { sortObjectArray } from '@utils/sort-by-object-property';
import AssetOverview from '../asset-overview.vue';
import AddCalendarEvents from './add-calendar-event.vue';
import UpdateCalendarEvents from './update-calendar-event.vue';
import ArchiveToastConfirmMixin from '../archive-toast-confirm-mixin';

export default {
  components: {
    AssetOverview,
    AddCalendarEvents,
    UpdateCalendarEvents,
  },

  mixins: [ErrorMixin, PageReadyMixin, ArchiveToastConfirmMixin],

  data() {
    return {
      calendarEventsReady: false,
      calendarEvents: [],
      calendarEventToUpdate: undefined,
      showArchived: false,
    };
  },

  beforeMount() {
    this.getCalendarEvents();
  },

  computed: {
    ready() {
      return this.calendarEventsReady;
    },
    sortedCalendarEvents() {
      return sortObjectArray(this.calendarEvents.slice(), 'name', false);
    },

    kableHeaders() {
      return {
        edit: {
          name: 'Edit',
          type: 'action',
        },
        title: {
          name: 'Title',
          filterable: true,
          sortable: true,
          type: 'url',
        },
        description: {
          name: 'Description',
        },
        completionTypes: {
          name: 'Completion Types',
        },
        displayTags: {
          name: 'Tags',
          type: 'tags',
          filterable: true,
          sortable: true,
        },
        archive: {
          name: 'Archive',
          type: 'action',
          sortable: true,
        },
        delete: {
          name: 'Delete',
          type: 'action',
        },
      };
    },

    kableRows() {
      return this.sortedCalendarEvents.map((val, index) => ({
        edit: {
          key: index,
          text: getIcon({ icon: 'edit' }),
        },
        title: {
          key: index,
          text: val.name,
          path: {
            name: 'curriculum_asset_details',
            params: {
              assetId: val.id,
              assetType: 'calendar_event',
            },
          },
        },
        completionTypes: this.formattedCompletionTypes(val.completion_types),
        displayTags: {
          tags: val.tags || [],
          sortBy: val.tags ? [...val.tags].sort().join(' ') : '',
        },
        archive: {
          text: this.assetArchiveButton(val.is_active),
        },
        delete: {
          key: index,
          text: getIcon({ color: 'var(--kate-danger-alt)', icon: 'trash' }),
        },
        ...val,
      })).filter(ce => (this.showArchived || ce.is_active));
    },
  },

  methods: {
    closeEditModal() {
      this.calendarEventToUpdate = undefined;
    },

    updateArchiveToggle(val) {
      this.showArchived = val;
    },

    formattedCompletionTypes(completionTypes) {
      if (completionTypes && completionTypes.length) {
        return `<ul>${completionTypes.map(_type => ['<li>', _type, '</li>'].join('')).join('')}</ul>`;
      }
      return '';
    },

    getCalendarEvents() {
      this.$logger.info('Getting calendar events');
      this.$Loading.start();
      this.calendarEventsReady = false;
      this.$http.get('/api/curriculum/admin/calendar_event')
        .then(result => {
          this.calendarEvents = result.data.calendar_events;
        }).catch(err => {
          if (err.response && err.response.status !== 404) {
            this.$logger.error('Error getting calendar events', undefined, err);
            this.showError(err);
          } else {
            this.$logger.warn('No calendar events found');
            this.calendarEvents = [];
          }
        }).then(() => {
          this.calendarEventsReady = true;
          this.$Loading.finish();
        });
    },

    deleteConfirmationCallback(calendarEventId) {
      this.$logger.info('Deleting calendar events', { calendarEventId }, true);
      this.$Loading.start();
      this.$http.delete(`/api/curriculum/admin/calendar_event/${calendarEventId}`)
        .then(() => {
          this.$logger.info('Calendar event have been deleted', { calendarEventId });
          this.$ktoast.success('Calendar event have been deleted');
          this.getCalendarEvents();
        }).catch(err => {
          this.$logger.autowarn('Error deleting calendar event', { calendarEventId }, err);
          this.showError(err);
        }).then(() => {
          this.$Loading.finish();
        });
    },

    deleteCalendarEvents(calendarEventId) {
      this.$ktoast.confirmToast('You are about to delete this calendar event.', 'warning', () => {
        this.deleteConfirmationCallback(calendarEventId);
      });
    },

    tableCallback(key, index, row, header) {
      if (header === 'archive') {
        this.tableArchiveCallback(row);
      }
      if (header === 'delete') {
        this.deleteCalendarEvents(row.id);
      }
      if (header === 'edit') {
        const calevent = this.calendarEvents.find(x => x.id === row.id);
        if (calevent && typeof calevent === 'object') {
          this.calendarEventToUpdate = JSON.parse(JSON.stringify(calevent));
        } else {
          this.calendarEventToUpdate = undefined;
        }
      }
    },

    archiveCallback(ceId) {
      this.$logger.info('Archiving calendar event', { ceId }, true);
      this.$Loading.start();
      this.$http.put(`/api/curriculum/admin/calendar_event/${ceId}/archive`)
        .then(() => {
          this.$logger.info('Calendar event has been archived', { ceId });
          this.$ktoast.success('Calendar event has been archived');
          this.getCalendarEvents();
        }).catch(err => {
          this.$logger.error('Error archiving calendar event', { ceId }, err);
          this.showError(err);
        }).then(() => {
          this.$Loading.finish();
        });
    },

    unarchiveCallback(ceId) {
      this.$logger.info('Unarchiving calendar event', { ceId }, true);
      this.$Loading.start();
      this.$http.put(`/api/curriculum/admin/calendar_event/${ceId}/unarchive`)
        .then(() => {
          this.$logger.info('Calendar event has been unarchived', { ceId });
          this.$ktoast.success('Calendar event has been unarchived');
          this.getCalendarEvents();
        }).catch(err => {
          this.$logger.error('Error unarchiving calendar event', { ceId }, err);
          this.showError(err);
        }).then(() => {
          this.$Loading.finish();
        });
    },
  },
};
</script>
