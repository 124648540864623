<template>
  <div>
    <h1>Privileged Users</h1>
    <div>
      <create-privileged-user class="panel"
        :roles="sortedRoles"
        @loading="(val) => loading = val"
        @user-created="getPrivilegedUsers"
      ></create-privileged-user>
      <k-panel v-if="rolesReady"
               :showContent="showRoleDescriptions"
               @toggle="showRoleDescriptions = !showRoleDescriptions"
               :title="'Roles'"
               :icon="'fa-info-circle'"
               :duotone="false"
      >
        <template #body>
          <ul class="roles">
            <li v-for="(role, idx) in sortedRoles" :key="idx">
              <p><span>{{ role.name.replaceAll('_', ' ') }}</span> - {{ role.description }}</p>
            </li>
          </ul>
        </template>
      </k-panel>
      <k-table
        :rows="kableRows"
        :headers="kableHeaders"
        :hideable="false"
        :max="10"
        @clicked="tableCallback"
      ></k-table>
      <update-privileged-user
        :privilegedUser="privilegedUserToUpdate"
        :roles="sortedRoles"
        :programmes="programmes"
        :employers="employers"
        @close="closeEditModal"
        @privileged-user-updated="getPrivilegedUsers"
        ref="editModal"
      ></update-privileged-user>
    </div>
  </div>
</template>

<style>
ul.roles {
  list-style: none;
  padding-left: 0;
}

ul.roles li > p > span {
  font-weight: bold;
  color: var(--kate-type-accent);
}

[data-theme="legacy"] ul.roles li > p > span {
  color: var(--kate-primary);
}

.k-table-container .k-table-header-is_data_mentor,
.k-table-container .k-table-key-is_data_mentor,
.k-table-container .k-table-header-is_coach,
.k-table-container .k-table-key-is_coach {
  text-align: center;
}

th.k-table-header.k-table-header-edit,
.k-table-container th {
  min-width: unset;
}

.k-table-container i.fas {
  font-size: 1em;
}

.k-table-container i.fas.fa-check-circle {
  color: var(--kate-primary);
}

.fas.fa-external-link-alt.acuity {
  font-size: 0.8em;
  margin-left: 5px;
}
</style>

<script>
import KTable from '@base-components/k-table.vue';
import ErrorMixin from '@mixins/error-mixins';
import PageReadyMixin from '@mixins/page-ready-mixin';
import KPanel from '@base-components/k-panel.vue';
import formatId from '@utils/format-user-id';
import { sortObjectArray } from '@utils/sort-by-object-property';
import UpdatePrivilegedUser from './update-privileged-user.vue';
import CreatePrivilegedUser from './create-privileged-user.vue';

export default {
  components: {
    'create-privileged-user': CreatePrivilegedUser,
    'update-privileged-user': UpdatePrivilegedUser,
    'k-table': KTable,
    'k-panel': KPanel,
  },

  mixins: [ErrorMixin, PageReadyMixin],

  data() {
    return {
      roles: [],
      rolesReady: false,
      programmes: [],
      programmesReady: false,
      employers: [],
      employersReady: false,
      privilegedUsers: [],
      privilegedUsersReady: false,
      privilegedUserId: undefined,
      loading: false,
      permissionsUpdated: false,
      showRoleDescriptions: false,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getRoles();
    this.getProgrammes();
    this.getPrivilegedUsers();
    this.getEmployers();
  },

  computed: {
    ready() {
      return this.rolesReady && this.programmesReady && this.privilegedUsersReady && this.employersReady && !this.loading;
    },
    privilegedUserToUpdate() {
      if (this.privilegedUserId) {
        return this.privilegedUsers.find(x => x.id === this.privilegedUserId);
      }
      return {};
    },
    kableHeaders() {
      return {
        edit: {
          name: 'Edit',
          type: 'action',
        },
        name: {
          name: 'User',
          sortable: true,
          filterable: true,
          type: 'url',
        },
        email: {
          name: 'Email',
          sortable: true,
          filterable: true,
        },
        roles: {
          name: 'Roles',
          type: 'tags',
          filterable: true,
          sortable: true,
        },
        is_coach: {
          name: 'Coach',
          sortable: true,
        },
        is_data_mentor: {
          name: 'Data Mentor',
          sortable: true,
        },
      };
    },
    kableRows() {
      return this.privilegedUsers.map((x, index) => ({
        id: x.id,
        edit: {
          key: index,
          text: '<i class="fas fa-edit"></i>',
        },
        name: {
          text: `${x.full_name}<br><span class="user-id">${formatId(x.id)}</span>`,
          path: {
            name: 'dashboard_students',
            params: {
              studentId: x.id,
            },
          },
        },
        email: x.email,
        roles: {
          tags: x.roles ? x.roles.map(r => r.role.replaceAll('_', ' ')).sort() : [],
          sortBy: x.roles ? [...x.roles].map(r => r.role).sort().join(' ') : '',
        },
        is_coach: this.getIcon(
          x.is_coach,
          x.is_coach ? this.getAcuityLinkHtml(x.coach_acuity_link) : undefined,
        ),
        is_data_mentor: this.getIcon(
          x.is_data_mentor,
          x.is_data_mentor ? this.getAcuityLinkHtml(x.data_mentor_acuity_link) : undefined,
        ),
      }));
    },
    sortedRoles() {
      return this.roles ? sortObjectArray(this.roles.map(x => ({ ...x, numberOfPermissions: x.permissions.length })), 'numberOfPermissions') : undefined;
    },
  },

  methods: {

    getRoles() {
      this.rolesReady = false;
      this.$logger.info('Getting roles');
      this.$http.get('/api/profile/roles').then(res => {
        this.$logger.info('Successfully retrieved roles');
        this.roles = res.data;
      }).catch(err => {
        this.$logger.autowarn('There was an error while attempting to retrieve roles', undefined, err);
        this.showError(err);
      }).then(() => {
        this.rolesReady = true;
      });
    },
    getProgrammes() {
      this.programmesReady = false;
      this.$logger.info('Getting programmes for privileged users');
      return this.$http.get('/api/curriculum/programmes?dashboard=true')
        .then(result => {
          this.$logger.info('Got programmes for privileged user');
          this.programmes = result.data.programmes;
        }).catch(err => {
          this.$logger.autowarn('Error getting programmes for privileged users', undefined, err);
          this.showError(err);
        }).then(() => {
          this.programmesReady = true;
        });
    },
    getPrivilegedUsers() {
      this.privilegedUsersReady = false;
      this.$logger.info('Getting privileged users');
      this.$http.get('/api/profile/users/privileged').then(res => {
        this.$logger.info('Successfully retrieved privileged users');
        this.privilegedUsers = res.data.users;
      }).catch(err => {
        this.$logger.autowarn('There was an error while attempting to retrieve privileged users', undefined, err);
        this.showError(err);
      }).then(() => {
        if (this.permissionsUpdated) {
          this.$ktoast.success('Permissions for user updated.');
        }
        this.privilegedUsersReady = true;
      });
    },
    getEmployers() {
      this.employersReady = false;
      this.$logger.info('Getting employers');
      this.$http.get('/api/profile/employers').then(res => {
        this.$logger.info('Successfully retrieved employers list');
        this.employers = res.data;
      }).catch(err => {
        this.$logger.autowarn('There was an error while attempting to retrieve employers list in privileged users', undefined, err);
        this.showError(err);
      }).then(() => {
        this.employersReady = true;
      });
    },
    tableCallback(key, index, row, column) {
      if (column === 'edit') {
        this.privilegedUserId = this.privilegedUsers.find(x => x.id === row.id).id;
      }
    },
    closeEditModal() {
      this.privilegedUserId = undefined;
      this.$refs.editModal.reset();
    },
    getIcon(val, additionalContent) {
      let html;
      if (val) {
        html = '<i class="fas fa-check-circle"></i>';
      } else {
        html = '<i class="fas fa-minus"></i>';
      }
      if (additionalContent) {
        html += `<br>${additionalContent}`;
      }
      return html;
    },
    getAcuityLinkHtml(acuityLink) {
      if (!acuityLink) {
        return '<span class="info-text">No acuity scheduling link specified</span>';
      }
      return `<a href="${acuityLink}">Acuity link<i class="fas fa-external-link-alt acuity"></i></a>`;
    },
  },
};
</script>
