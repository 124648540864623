<template>
  <div class="content-with-spacing">
    <div class="dashboard-header">
      <h1>Programme Summary</h1>
    </div>
    <hr>
    <template v-if="programmes && programmes.length > 0">
      <div class="programme-summary-control-header">
        <div v-if="programmes && programmes.length > 0 && programmeId" class="change-programme-container">
          <span>Programme:
            <div v-if="programmeId" class="programme-id-container">
              ID: <b>{{programmeId}}</b>
            </div>
          </span>
          <button class="btn btn-tertiary" @click="openChangeProg">{{programmeName || 'Choose Programme'}}</button>
          <change-programme
            v-if="programmes"
            v-model="programmeId"
            :programmes="programmes"
            @close="closeChangeProg"
            :show="showChangeProg">
          </change-programme>
          <button class="btn btn-primary copy-emails"
            v-if="programmeMemberEmailsReady && this.programmeMemberEmails.length > 0"
            @click="copyEmails">
            Copy Emails <i class="fas fa-copy"></i>
          </button>
        </div>
        <div class="k-dashboard-auto-toggle">
          <k-tooltip :text="tooltipText">
            <span>Auto refresh</span>
          </k-tooltip>
          <k-toggle v-model="autoRefresh" :round="true"></k-toggle>
        </div>
      </div>
      <programme-summary-scores ref="dashtable" :scores="scores" :paks="paks" :quizzes="quizzes"/>
      <div v-if="scores.length === 0 && ready" style="padding-top: 50px;">
        <empty-placeholder
          title="No score data"
          info="No submissions have been made in this programme"
        ></empty-placeholder>
      </div>
    </template>
    <div v-else-if="ready" style="padding-top: 50px;">
      <empty-placeholder
        title="No programmes available"
        info="You do not have access to view any programmes on the dashboard"
      ></empty-placeholder>
    </div>
  </div>
</template>

<style>
#content .dashboard-header h1 {
  margin: 0;
}
</style>

<style scoped>
.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.dashboard-header + hr {
  margin-top: 0;
}

.dashboard-header button.user-search i {
  margin-right: 5px;
}

.programme-summary-control-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.change-programme-container,
.k-dashboard-auto-toggle {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.change-programme-container {
  gap: 15px;
}

.change-programme-container .programme-id-container {
  font-weight: 400;
  font-size: 0.9em;
}

.change-programme-container .programme-id-container b {
  color: var(--kate-type-accent);
}

.k-dashboard-auto-toggle span {
  align-self: center;
  padding-right: 10px;
}

.k-dashboard-auto-toggle .switch {
  margin-bottom: 0;
}

.copy-emails i {
  margin-left: 5px;
}

@media only screen and (max-width: 767px) {
  .programme-summary-control-header {
    flex-wrap: wrap;
    gap: 10px;
  }

  .k-dashboard-auto-toggle {
    display: block;
    text-align: right;
  }

  .k-dashboard-auto-toggle span {
    padding-right: 0;
  }
}
</style>

<script>
import axios from 'axios';
import StorageMixin from '@mixins/storage-mixins';
import ErrorMixin from '@mixins/error-mixins';
import PageReadyMixin from '@mixins/page-ready-mixin';
import EmptyPlaceholder from '@base-components/empty-placeholder.vue';
import KTooltip from '@base-components/k-tooltip.vue';
import KToggle from '@base-components/forms/k-toggle.vue';
import { copyToClipboard } from '@utils/copy-to-clipboard';
import ChangeProgramme from '../../components/change-programme.vue';
import DashSummaryScores from './programme-summary-scores.vue';

// value is in ms
const REFRESH_INTERVAL = 10000;
const CancelToken = axios.CancelToken;

export default {
  mixins: [StorageMixin, ErrorMixin, PageReadyMixin],
  data() {
    return {
      paks: [],
      scores: [],
      quizzes: [],
      programmeMemberEmails: [],
      programmes: undefined,
      programmesReady: false,
      paksReady: false,
      scoresReady: false,
      quizzesReady: false,
      programmeMemberEmailsReady: false,
      autoRefresh: false,
      refreshInterval: undefined,
      // cancel tokens
      scoresCall: null,
      showChangeProg: false,
    };
  },
  components: {
    'programme-summary-scores': DashSummaryScores,
    'change-programme': ChangeProgramme,
    'empty-placeholder': EmptyPlaceholder,
    'k-toggle': KToggle,
    'k-tooltip': KTooltip,
  },
  mounted() {
    this.$Loading.start();
  },
  beforeMount() {
    this.getProgrammes().then(() => {
      this.loadProgrammeId(); // changing prog ID triggers refresh
      this.getData();
      this.saveProgrammeId(this.programmeId);
    });
  },
  watch: {
    autoRefresh() {
      this.toggleRefresh();
    },
    programmeId(id) {
      if (this.$route.name !== 'monitor_programme_summary') {
        // Leaving the page and programmeId param being set to undefined
        return;
      }
      if (id) {
        this.saveProgrammeId(id);
        this.getData();
      } else {
        // Load the saved programme
        this.loadProgrammeId();
      }
    },
  },
  computed: {
    programmeId: {
      get() {
        if (!this.$route.params.programmeId) {
          return undefined;
        }
        return Number(this.$route.params.programmeId);
      },
      set(id) {
        this.changeProgramme(id);
      },
    },
    ready() {
      if (this.programmesReady) {
        // If programmes are available then ready waits for scores, paks, quizzes
        return (this.programmes && this.programmes.length > 0) ? this.scoresReady && this.paksReady && this.quizzesReady : true;
      }
      return false;
    },
    programmeName() {
      if (this.programmeId === undefined || this.programmes === undefined) {
        return '';
      }
      for (let i = 0; i < this.programmes.length; i++) {
        if (this.programmes[i].id === this.programmeId) {
          return this.programmes[i].name;
        }
      }
      return '';
    },
    refreshIntervalSeconds() {
      return REFRESH_INTERVAL / 1000;
    },
    tooltipText() {
      return `Refresh score data every ${this.refreshIntervalSeconds} seconds`;
    },
  },
  methods: {
    saveProgrammeId(id) {
      const settings = this.loadFromStorage('programme-summary') || {};
      settings.programmeId = id;
      this.dumpToStorage('programme-summary', settings);
    },
    loadProgrammeId() {
      if (this.programmeId) {
        return;
      }
      const settings = this.loadFromStorage('programme-summary');
      if (settings !== undefined && settings.programmeId) {
        this.changeProgramme(settings.programmeId);
      }
    },
    changeProgramme(id) {
      if (id === this.programmeId) {
        return;
      }
      this.$router.push({
        name: 'monitor_programme_summary',
        params: {
          programmeId: id,
        },
      });
    },
    toggleRefresh() {
      this.$logger.info('Toggling auto refresh', { autoRefresh: this.autoRefresh }, true);
      clearInterval(this.refreshInterval);
      if (this.autoRefresh) {
        this.refreshInterval = setInterval(() => {
          this.getData();
        }, REFRESH_INTERVAL);
      }
    },
    getData() {
      this.getPaks();
      this.getQuizzes();
      this.getScores();
      this.getProgrammeMemberEmails();
    },
    getProgrammes() {
      this.programmesReady = false;
      this.$logger.info('Getting programmes for user');
      return this.$http.get('/api/curriculum/programmes?dashboard=true')
        .then(result => {
          this.$logger.info('Got programmes for user');
          this.programmes = result.data.programmes;
          if (!this.programmeId && this.programmes.length > 0) {
            this.changeProgramme(result.data.programmes[0].id);
          }
        }).catch(err => {
          this.$logger.error('Error getting programmes for user', undefined, err);
        }).then(() => {
          this.programmesReady = true;
        });
    },
    getScores() {
      if (this.programmeId === undefined) {
        return;
      }
      this.scoresReady = false;
      if (this.scoresCall) {
        this.scoresCall.cancel();
      }
      this.$logger.info('Getting scores for programme', { programmeId: this.programmeId }, true);
      this.scoresCall = CancelToken.source();
      this.$http.get(`/api/stats/programmes/${this.programmeId}/scores`, {
        cancelToken: this.scoresCall.token,
      }).then(result => {
        this.$logger.info('Got scores for programme', { programmeId: this.programmeId });
        this.scores = result.data;
      }).catch(err => {
        if (axios.isCancel(err)) {
          return;
        }
        if (err.response && err.response.status === 404) {
          this.scores = [];
          this.$logger.warn('No scores for programme', { programmeId: this.programmeId }, err);
        } else {
          this.$logger.error('Error getting scores for programme', { programmeId: this.programmeId }, err);
        }
      }).then(() => {
        this.scoresReady = true;
        if (this.$refs.dashtable) {
          // Ref only exists if the dashboard table is actually rendered
          this.$refs.dashtable.constructScoresRows();
        }
      });
    },
    getPaks() {
      if (this.programmeId === undefined) {
        return;
      }
      this.paksReady = false;
      this.$logger.info('Getting PAKs for programme', { programmeId: this.programmeId }, true);
      this.$http.get(`/api/curriculum/programmes/${this.programmeId}/pak`).then(result => {
        this.$logger.info('Got PAKs for programme', { programmeId: this.programmeId });
        this.paks = result.data.paks;
      }).catch(err => {
        if (err.response && err.response.status === 404) {
          this.paks = [];
        } else {
          this.$logger.info('Error getting PAKs for programme', { programmeId: this.programmeId });
          this.showError();
        }
      }).then(() => {
        this.paksReady = true;
      });
    },
    getQuizzes() {
      if (this.programmeId === undefined) {
        return;
      }
      this.quizzesReady = false;
      this.$logger.info('Getting quizzes for programme', { programmeId: this.programmeId }, true);
      this.$http.get(`/api/curriculum/programmes/${this.programmeId}/quiz`).then(result => {
        this.$logger.info('Got quizzes for programme', { programmeId: this.programmeId });
        this.quizzes = result.data.quizzes;
      }).catch(err => {
        if (err.response && err.response.status === 404) {
          this.quizzes = [];
        } else {
          this.$logger.info('Error getting quizzes for programme', { programmeId: this.programmeId });
          this.showError();
        }
      }).then(() => {
        this.quizzesReady = true;
      });
    },
    getProgrammeMemberEmails() {
      if (this.programmeId === undefined) {
        return;
      }
      this.programmeMemberEmailsReady = false;
      this.$logger.info('Getting programme member emails for programme', { programmeId: this.programmeId }, true);
      this.$http.get(`/api/curriculum/programmes/${this.programmeId}/emails`).then(result => {
        this.programmeMemberEmails = result.data.emails;
      }).catch(err => {
        this.$logger.info('Error getting programme member emails for programme', { programmeId: this.programmeId });
        this.showError(err);
      }).then(() => {
        this.programmeMemberEmailsReady = true;
      });
    },
    copyEmails() {
      const addressList = this.programmeMemberEmails.map(x => x.email).join(',');
      copyToClipboard(addressList);
      this.$ktoast.copy('Copied address list to clipboard', { goAway: 1500 });
    },
    openChangeProg() {
      this.showChangeProg = true;
    },
    closeChangeProg() {
      this.showChangeProg = false;
    },
  },
};
</script>
