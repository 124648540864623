import { defineStore } from 'pinia';
import copyObject from '@utils/copy-object';
import { sortObjectArray } from '@utils/sort-by-object-property';

const useReleaseModuleStore = defineStore('releaseModuleStore', {
  state: () => ({
    // originalModules does not maintain the order - it needs to be reorder
    // so we have access to original data. Newly added modules will be added as well
    // so we can track the reordering
    originalModules: [],
    activeModules: [],
  }),

  getters: {
    getModuleProperty: state => (index, property) => {
      // only used for badges and we don't want default
      const module = state.activeModules[index];

      if (module?.updates && property in module.updates) {
        // if already updated just fetch that
        return module.updates[property];
      }
      // return badges attached to module
      return module?.[property];
    },

    getModuleUpdates: state => index => {
      if (!state.activeModules[index]) return null;
      return state.activeModules[index].updates || {};
    },

    getModuleValue: state => (index, property) => {
      if (!state.activeModules[index]) return null;

      if (state.activeModules[index]?.updates && property in state.activeModules[index].updates) {
        return state.activeModules[index].updates[property];
      }
      return state.originalModules[index]?.[property];
    },

    getBadgesToDisplay: state => index => {
      if (state.hasModulePropertyChanged(index, 'badges_in_module')) {
        return state.activeModules[index].updates?.badges_in_module;
      }

      return state.originalModules[index]?.badges_in_module;
    },

    hasModulePropertyChanged: state => (index, propertyName) => {
      if (
        !state.activeModules[index]
          || !state.activeModules[index].updates
          || !(propertyName in state.activeModules[index].updates)
      ) {
        return false;
      }

      const updatedValue = state.activeModules[index].updates?.[propertyName];
      const originalValue = state.originalModules[index]?.[propertyName];

      if (
        propertyName === 'badges_in_module'
          && Array.isArray(updatedValue)
          && Array.isArray(originalValue)
      ) {
        if (updatedValue.length !== originalValue.length) {
          return true;
        }

        // Compare badge IDs
        const originalIds = new Set(originalValue.map(item => item.id));
        const updatedIds = new Set(updatedValue.map(item => item.id));

        if (originalIds.size !== updatedIds.size) {
          return true;
        }

        if (Array.from(originalIds).some(id => !updatedIds.has(id))) {
          return true;
        }

        return false;
      }

      // For regular properties, direct comparison
      return updatedValue !== originalValue;
    },

    hasModuleChanges: state => index => Boolean(
      state.activeModules[index]
        && state.activeModules[index].updates
        && Object.keys(state.activeModules[index].updates).length > 0,
    ),
  },

  actions: {
    clearModules() {
      this.activeModules = [];
      this.originalModules = [];
    },

    setOriginalModules(modules) {
      this.originalModules = [];
      this.originalModules = sortObjectArray(copyObject(modules));
    },

    updateOriginalModulesOrder() {
      // Simply make originalModules match the structure of activeModules
      // This ensures they stay in sync through any reordering operations
      const newOriginalModules = [];

      // For each active module, find or create the corresponding original module
      this.activeModules.forEach((activeModule, index) => {
        if (activeModule.addedModule) {
          // For newly added modules, use the active module data directly
          // since there's no original state to preserve
          newOriginalModules.push({ ...activeModule });
        } else if (index < this.originalModules.length) {
          // For existing modules, find the original module with matching ID
          const matchingOriginal = this.originalModules.find(m => m.id === activeModule.id);
          if (matchingOriginal) {
            newOriginalModules.push(matchingOriginal);
          } else {
            // Fallback: use the one at the same index if no match found
            newOriginalModules.push(this.originalModules[index]);
          }
        }
      });

      this.originalModules = newOriginalModules;
    },

    setActiveModules(modules) {
      this.activeModules = [];
      const moduleData = sortObjectArray(copyObject(modules));
      moduleData.forEach(module => this.activeModules.push({
        ...module,
        updates: module.updates || {},
      }));
    },

    setActiveModule(moduleData) {
      const moduleCopy = copyObject(moduleData);
      this.activeModules.push({
        ...moduleCopy,
        updates: {},
      });
    },

    setOriginalModule(moduleData) {
      const moduleCopy = copyObject(moduleData);
      this.originalModules.push({ ...moduleCopy });
    },

    removeActiveModule(index) {
      if (this.activeModules[index] && index >= 0 && index < this.activeModules.length) {
        this.activeModules.splice(index, 1);
      }
    },
    removeOriginalModule(index) {
      if (this.originalModules[index] && index >= 0 && index < this.originalModules.length) {
        this.originalModules.splice(index, 1);
      }
    },

    updateModuleProperty(index, property, value) {
      if (!this.activeModules[index]) return;
      if (!this.activeModules[index].updates) {
        this.activeModules[index].updates = {};
      }
      this.activeModules[index].updates[property] = value;
    },

    resetModuleChanges(index) {
      if (this.activeModules[index]) {
        this.activeModules[index] = {
          ...this.originalModules[index],
          updates: {},
        };
      }
    },

    withdrawModule(index) {
      if (!this.activeModules[index]) return;
      this.updateModuleProperty(index, 'release_date', null);
    },

    addBadgeToModule(index, badge) {
      if (!this.activeModules[index]) return;
      if (!this.activeModules[index]?.updates) {
        this.activeModules[index].updates = {};
      }

      if (Object.keys(this.activeModules[index].updates).length === 0) {
        // make sure existing badges are copied into update
        this.activeModules[index].updates = {
          badges_in_module: copyObject(this.getModuleProperty(index, 'badges_in_module') || []),
        };
      }
      const badges = this.activeModules[index].updates.badges_in_module || [];

      if (!badges.some(b => b.id === badge.id)) {
        badges.push(copyObject(badge));
        this.activeModules[index].updates.badges_in_module = badges;
      }
    },

    removeBadgeFromModule(index, badgeId) {
      if (!this.activeModules[index]) return;
      // Initialize updates if not already set
      if (Object.keys(this.activeModules[index].updates).length === 0) {
        this.activeModules[index].updates = {
          badges_in_module: copyObject(this.getModuleProperty(index, 'badges_in_module') || []),
        };
      }

      const badges = this.activeModules[index].updates.badges_in_module || [];
      this.activeModules[index].updates.badges_in_module = badges.filter(b => b.id !== badgeId);
    },

    setModuleProperty(index, property, newVal) {
      if (!this.activeModules[index]) return;
      if (newVal === undefined && (property === 'expected_completion_date' || property === 'release_date')) {
        this.updateModuleProperty(index, property, null);
        return;
      }
      this.updateModuleProperty(index, property, newVal);
    },
  },
});

export default useReleaseModuleStore;
