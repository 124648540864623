<template>
  <div v-if="notesReady" class="notes-overview">
    <div class="header-actions">
      <div v-if="notes.length > 0" class="search">
        <k-text-search v-model="filterText"></k-text-search>
      </div>
      <div v-if="notes.length > 0" class="sort">
        <button class="btn btn-outlined btn-sort" aria-label="Toggle sort" title="Toggle sort" @click="toggleSort">
          <span>
            Sort
            <i class="fas" :class=" sortDescending ? 'fa-sort-amount-down-alt' : 'fa-sort-amount-up'"></i>
          </span>
        </button>
      </div>
      <div v-if="hasInteractionPermission" class="header-buttons">
        <button v-if="!addingNewNote" class="btn btn-primary add-new" @click="addingNewNote = !addingNewNote">
          Add new <i class="fa-solid fa-plus"></i>
        </button>
        <button v-if="addingNewNote" class="btn btn-default cancel-btn" @click="addingNewNote = false">
          Cancel
        </button>
        <button v-if="addingNewNote && !savingNote" class="btn btn-success save-btn" @click="addNewNote" :disabled="disableSave">
          Save new note <i class="fa-solid fa-check"></i>
        </button>
        <span class="btn btn-loading" v-else-if="savingNote" disabled>
          Saving <i class="fa-duotone fa-spinner fa-spin"></i>
        </span>
      </div>
    </div>
    <div class="main-notes-container">
      <div class="note-editor" :class="addingNewNote ? 'showing-editor' : 'hidden-editor'" >
        <div>
          <label class="k-custom-checkbox share-note-check">
            <input type="checkbox" v-model="shareWithEmployer" class="hidden-checkbox"/>
            <span class="k-custom-checkbox-box light"></span>
            <span class="k-custom-checkbox-text">Share note with employer</span>
          </label>
          <k-text-editor
            id="noteBody"
            v-model="body">
          </k-text-editor>
        </div>
      </div>
      <div class="notes-list" v-if="notes.length > 0">
        <note v-for="note in filteredNotes" :key="note.note_id"
          :programmeMemberId="programmeMemberId"
          :note="note"
          @openEditModal="openEditModal(note)"
          :filterText="filterText"
          :hasInteractionPermission="hasInteractionPermission"
          @update="getNotes()">
        </note>
        <div v-if="maxRows < notes.length" class="show-more">
          <button v-if="totalRows < notes.length && filteredNotes.length >= totalRows"  @click="additionalRows += 5">
            <span>Show more</span>
            <i class="fas fa-angle-down"></i>
          </button>
          <button v-if="totalRows > 8" @click="additionalRows -= 5">
            <span>Show less</span>
            <i class="fas fa-angle-up"></i>
          </button>
        </div>
      </div>
      <div class="empty-note-placeholder" v-else>
        <span>No notes found for this user.</span>
      </div>
    </div>
    <edit-note
      class="edite-note-modal"
      @close="close"
      @edited="getNotes()"
      :show="showEditModal"
      :note="editNote"
      :programmeMemberId="programmeMemberId"
    >
    </edit-note>
  </div>
  <div v-else class="content-spinner">
    <i class="fa-duotone fa-spinner fa-spin"></i>
  </div>
</template>

<style>
.notes-overview .header-actions .search input.form-control {
  padding: 5px 5px 5px 30px;
}

.notes-overview .k-search-dropdown-adv-content {
  min-width: 180px;
  left: 50%;
  transform: translateX(-50%);
}

.notes-overview .k-search-dropdown-menu .btn-primary {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* K-Editor */
.main-notes-container .note-editor #k-text-editor .custom-editor {
  margin: 0;
}

.main-notes-container .note-editor.hidden-editor {
  width: 0;
  height: 0;
}

.main-notes-container .note-editor.hidden-editor > div {
  opacity: 0;
  max-height: 0;
  margin: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in, max-height 0.25s;
}

.main-notes-container .note-editor.showing-editor > div {
  opacity: 1;
  max-height: 800px;
  margin-bottom: 25px;
  overflow: auto;
  pointer-events: unset;
  transition: opacity 0.2s ease-in, max-height 0.25s;
}

.notes-overview .share-note-check {
  cursor: pointer;
  margin: 15px 0;
  width: fit-content;
  float: right;
}
</style>

<style scoped>
.notes-overview {
  position: relative;
}

.header-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  align-items: center;
  gap: 15px;
}

.btn-sort:focus span {
  mix-blend-mode: difference;
}

.main-notes-container {
  margin-top: 20px;
  height: 300px;
  overflow-y: auto;
}

@media (max-width: 820px) {
  .header-actions {
    justify-content: space-evenly;
  }
}
</style>

<script>
import ErrorMixin from '@mixins/error-mixins';
import TimeMixin from '@mixins/time-mixins';
import KTextSearch from '@base-components/k-text-search.vue';
import KTextEditor from '@base-components/k-text-editor.vue';
import { sortObjectArray } from '@utils/sort-by-object-property';
import EditNote from './student_components/edit-note.vue';
import Note from './student_components/note.vue';

export default {
  components: {
    KTextSearch,
    Note,
    KTextEditor,
    EditNote,
  },

  props: {
    programmeMemberId: Number,
    isLearnerSupport: Boolean,
    maxRows: {
      type: Number,
      default: 5,
    },
  },

  mixins: [ErrorMixin, TimeMixin],

  data() {
    return {
      body: '',
      filterText: '',
      sortDescending: false,
      additionalRows: 0,
      addNote: false,
      savingNote: false,
      addingNewNote: false,
      notesReady: false,
      shareWithEmployer: true,
      showEditModal: false,
      notes: [],
      editNote: {},
    };
  },

  beforeMount() {
    this.getNotes();
  },

  watch: {
    programmeMemberId() {
      this.getNotes();
    },
  },

  computed: {
    hasInteractionPermission() {
      return this.$permissions.hasPermission('manage_user_notes') || this.isLearnerSupport;
    },

    validForm() {
      return Boolean(this.body);
    },

    disableSave() {
      return !this.validForm || this.body.length < 10 || this.savingNote;
    },

    totalRows() {
      return this.maxRows + this.additionalRows;
    },

    sortedNotes() {
      if (this.sortDescending) {
        // Default sort: Newest date created first to oldest
        return sortObjectArray(this.notes, 'date_created', false).slice(0, this.totalRows);
      }
      return sortObjectArray(this.notes, 'date_created', true).slice(0, this.totalRows);
    },

    filteredNotes() {
      if (this.filterText.length < 1) {
        return this.sortedNotes;
      }
      const regOption = new RegExp(this.filterText, 'ig');
      return this.notes.filter(x => {
        // Can search by note content, date created/updated, author.
        if ((x.note && x.note.match(regOption))
            || (x.author_name && x.author_name.match(regOption))
            || (this.parseTimestamp(x.date_created) && this.parseTimestamp(x.date_created).match(regOption))
            || (this.parseTimestamp(x.date_updated) && this.parseTimestamp(x.date_updated).match(regOption))) {
          return true;
        }
        return false;
      });
    },
  },

  methods: {
    toggleSort() {
      this.sortDescending = !this.sortDescending;
    },

    getNotes() {
      if (!this.programmeMemberId) {
        this.notesReady = true;
        return;
      }
      this.$logger.info('Getting notes', { programmeMemberId: this.programmeMemberId }, true);
      this.notesReady = false;
      this.$http.get(`/api/profile/programme-member/${this.programmeMemberId}/notes`).then(result => {
        this.notes = result.data;
        this.$logger.info('Got notes', { programmeMemberId: this.programmeMemberId });
      }).catch(error => {
        this.$logger.error('Error getting notes', { programmeMemberId: this.programmeMemberId }, error);
        this.showError(error);
      }).then(() => {
        this.notesReady = true;
        this.showEditModal = false;
      });
    },

    addNewNote() {
      this.savingNote = true;
      this.$logger.info('Adding new note', undefined, true);
      this.$http.post(`/api/profile/programme-member/${this.programmeMemberId}/notes`, {
        body: this.body,
        share_with_employer: this.shareWithEmployer,
      }).then(() => {
        this.$logger.info('New note added');
        this.$ktoast.success('New note added');
        this.$emit('note-added');
        this.body = '';
        this.shareWithEmployer = true;
        this.getNotes();
        this.savingNote = false;
        this.addingNewNote = false;
      }).catch(err => {
        this.$logger.error('Error adding new note', undefined, err);
        this.showError(err);
        this.savingNote = false;
      });
    },

    openEditModal(note) {
      this.editNote = note;
      this.showEditModal = true;
    },

    close() {
      this.showEditModal = false;
      this.editNote = {};
    },
  },
};
</script>
