<template>
  <div class="activity-feed">
    <div v-if="activityFeed.length > 0" class="csv-button">
      <k-tooltip text="Download activity data as a CSV" position="left">
        <button class="btn-primary" @click="downloadActivityData" aria-label="Download" title="Download"><i class="fas fa-download"></i></button>
      </k-tooltip>
    </div>
    <div class="feed-container">
      <div v-if="ready">
        <template v-if="activityFeed.length > 0">
          <ul>
            <li v-for="row in activityFeed" :key="`${row.content_type}-${row.content_id}-${row.date_timestamp}`">
              <div class="activity-icon">
                <div class="icon-container">
                  <i :class="getActivityIcon(row)" class="fas k-asset-icon"></i>
                </div>
              </div>
              <div class="activity-content">
                <p>
                  <span v-html="getActivityText(row)" class="activity-text"></span>
                  <a :href="getContentLink(row)" target="_blank">{{ row.content_name }}</a>
                  <template v-if="row.module_id">
                    in <a :href="getModuleLink(row)" target="_blank">{{ row.module_name }}</a>
                  </template>
                  <template v-if="row.activity_type === 'submission'">
                    <span v-html="getScore(row)" :class="getScoreClass(row)" class="score"></span>
                    <k-tooltip v-if="row.additional_info.overall_score !== null && row.additional_info.overall_score !== undefined && !row.additional_info.first_attempt" text="Percentage point difference from previous submission">
                      ( <span v-html="getScoreDiff(row)" :class="getScoreDiffClass(row)" class="score-diff"></span> )
                    </k-tooltip>
                  </template>
                </p>
                <p class="timestamp">
                  {{ parseTimestamp(row.date_timestamp) }}
                </p>
                <hr/>
              </div>
            </li>
          </ul>
          <div v-if="maxRows < activityData.length" class="show-more">
            <button v-if="totalRows < activityData.length"  @click="additionalRows += 5">
              <span>Show more</span>
              <i class="fas fa-angle-down"></i>
            </button>
            <button v-if="totalRows > 8" @click="additionalRows -= 5">
              <span>Show less</span>
              <i class="fas fa-angle-up"></i>
            </button>
          </div>
        </template>
        <empty-placeholder v-else
          title="No activity found"
          info="The student has yet to make a submission, mark any content complete or use the Knowledge Base"
        ></empty-placeholder>
      </div>
      <div v-else class="content-spinner">
        <i class="fa-duotone fa-spinner fa-spin"></i>
      </div>
    </div>
  </div>
</template>

<style>
.activity-text > b {
  color: var(--kate-type-warning);
}
</style>

<style scoped>
.activity-feed {
  position: relative;
  padding-top: 20px;
}

.activity-feed .csv-button {
  position: absolute;
  right: 60px;
  top: -43px;
}

.activity-feed .csv-button .btn-primary {
  padding: 5px 15px;
}

.feed-container {
  height: 300px;
  overflow-y: auto;
  display: flex;
  overflow-x: hidden;
}

ul {
  padding-left: 0;
  list-style: none;
}

ul li {
  display: flex;
  align-content: center;
}

ul li > .activity-icon {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-right: 10px;
  position: relative;
}

ul li > .activity-icon::before {
  content: "";
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 2px;
  background-color: var(--kate-type-light);
}

ul li > .activity-icon > .icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  z-index: 1;
  border: solid 2px var(--kate-mono-0);
  background-color: var(--kate-background-body);
}

.score {
  margin-top: 5px;
  font-weight: bold;
  padding-left: 0.5em;
}

.score-diff {
  font-weight: bold;
  cursor: pointer;
}

.score.success,
.score-diff.success {
  color: var(--kate-type-success);
}

.score.danger,
.score-diff.danger {
  color: var(--kate-danger-alt);
}

.score.warning,
.score-diff.warning {
  color: var(--kate-type-warning);
}

.activity-content p {
  margin-bottom: 0;
}

.activity-content hr {
  margin-bottom: 15px;
  margin-top: 10px;
}

p.timestamp {
  font-size: 0.8em;
  color: var(--kate-type-light);
}

.fas.fa-comments {
  color: var(--kate-logo-purple);
}
</style>

<script>
import useGlobalStore from '@stores/global';
import TimeMixin from '@mixins/time-mixins';
import AssetIconMixin from '@mixins/asset-icon-mixin';
import KTooltip from '@base-components/k-tooltip.vue';
import EmptyPlaceholder from '@base-components/empty-placeholder.vue';
import CsvMixin from '@mixins/csv-mixins';
import { sortObjectArray } from '@utils/sort-by-object-property';
import { ASSET_TYPES } from '../../constants';

const CALENDAR_EVENT_COMPLETION_TYPES = {
  live: 'Attended <b>live</b> session for ',
  recorded: 'Watched <b>recording</b> of ',
};

export default {
  mixins: [TimeMixin, AssetIconMixin, CsvMixin],

  components: {
    KTooltip,
    EmptyPlaceholder,
  },

  props: {
    studentId: {
      type: Number,
      required: true,
    },
    studentName: {
      type: String,
    },
    programmeId: {
      type: Number,
      required: true,
    },
    programmeName: {
      type: String,
    },
    maxRows: {
      type: Number,
      default: 8,
    },
  },

  data() {
    return {
      store: useGlobalStore(),
      activityData: [],
      ready: false,
      additionalRows: 0,
    };
  },

  beforeMount() {
    this.getActivityData();
  },

  watch: {
    programmeId() {
      this.getActivityData();
    },
    studentId() {
      this.getActivityData();
    },
  },

  computed: {
    totalRows() {
      return this.maxRows + this.additionalRows;
    },
    activityFeed() {
      return sortObjectArray(this.activityData, 'date_timestamp', true).slice(0, this.totalRows);
    },
  },

  methods: {
    getActivityText(row) {
      if (row.activity_type === 'submission') {
        return `${row.additional_info.first_attempt ? 'First' : 'New'} <b>submission</b> on `;
      }
      if (row.activity_type === 'completion') {
        if (row.content_type === 'calendar_event') {
          return CALENDAR_EVENT_COMPLETION_TYPES[row.additional_info.completion_type];
        }
        return 'Marked <b>complete</b> on ';
      }
      if (row.content_type === 'knowledge_base') {
        return `Posted new <b>${row.activity_type === 'question' ? 'question' : 'reply</b> on'} `;
      }
      return undefined;
    },
    getActivityIcon(row) {
      if (row.content_type === 'knowledge_base') {
        return 'fa-comments';
      }
      return this.getAssetIcon(row.content_type);
    },
    getScore(row) {
      if (row.additional_info.overall_score !== null && row.additional_info.overall_score !== undefined) {
        return `${parseFloat(row.additional_info.overall_score).toFixed(1)}%`;
      }
      return 'Score Generation Failed';
    },
    getScoreClass(row) {
      if (row.additional_info.overall_score > 75) {
        return 'success';
      }
      if (row.additional_info.overall_score < 30) {
        return 'danger';
      }
      return 'warning';
    },
    getScoreDiff(row) {
      const sd = row.additional_info.score_diff;
      if (sd === 0) {
        return '<i class="fas fa-minus"></i> 0%';
      }
      return `<i class="fas fa-${sd > 0 ? 'caret-up' : 'caret-down'}"></i> ${parseFloat(Math.abs(sd)).toFixed(1)}%`;
    },
    getScoreDiffClass(row) {
      const sd = row.additional_info.score_diff;
      if (sd === 0) {
        return '';
      }
      return sd > 0 ? 'success' : 'danger';
    },
    getModuleLink(row) {
      return `${this.store.appUrl}/modules/${row.module_id}`;
    },
    getContentLink(row) {
      if (row.activity_type === 'submission') {
        return `/student/${this.studentId}/${row.content_type}/${row.content_id}/feedback`;
      }
      if (row.content_type === 'knowledge_base') {
        return `/knowledge/thread/${row.additional_info.question_id}`;
      }
      if (row.activity_type === 'completion') {
        return `${this.store.appUrl}/modules/${row.module_id}/${ASSET_TYPES[row.content_type].routeName}/${row.content_id}`;
      }
      return undefined;
    },
    getActivityData() {
      this.$logger.info('Getting user activity on programme', { studentId: this.studentId, programmeId: this.programmeId }, true);
      this.$http.get(`/api/stats/activity/programme/${this.programmeId}/user/${this.studentId}`)
        .then(response => {
          this.$logger.info('Got user activity', { studentId: this.studentId, programmeId: this.programmeId });
          this.activityData = response.data.activity_feed;
        })
        .catch(err => {
          if (this.$http.isWarning(err)) {
            this.$logger.warn('Error getting user activity', { studentId: this.studentId, programmeId: this.programmeId }, err);
          } else {
            this.$logger.error('Error getting user activity', { studentId: this.studentId, programmeId: this.programmeId }, err);
          }
          this.activityData = [];
        })
        .then(() => {
          this.ready = true;
        });
    },
    downloadActivityData() {
      const headers = {
        user_id: 'User ID',
        user: 'Name',
        programme: 'Programme',
        date_timestamp: 'Timestamp',
        module_name: 'Module',
        activity_type: 'Activity Type',
        content_type: 'Content Type',
        content_name: 'Content Name',
        overall_score: 'Score',
        score_diff: 'Score Diff',
        first_attempt: 'Attempt',
        completion_type: 'Completion Type',
        question_id: 'Question ID',
      };
      const rows = sortObjectArray(this.activityData.map(x => ({
        user_id: this.studentId,
        user: this.studentName,
        programme: this.programmeName,
        ...x,
        ...x.additional_info,
      })), 'date_timestamp', true);
      this.downloadCsvFromTable(headers, rows, 'activity-data.csv');
      this.$logger.info('Successfully downloaded student activity data', { studentId: this.studentId, programmeId: this.programmeId }, true);
    },
  },
};
</script>
