<template>
  <div class="panel">
    <div class="controls">
      <button v-if="renderMode" class="btn btn-primary" @click="enterEditMode">
        Edit Quiz
        <i class="fas fa-edit"></i>
      </button>
      <button v-else class="btn btn-primary" @click="enterRenderMode">Preview Quiz</button>
    </div>
    <div v-if="quiz && quizReady" class="panel-body">
      <div v-if="renderMode">
        <div class="heading-container">
          <div class="title-container">
            <h1>
              {{ quiz.name }}
              <span class="read-only">Read-Only</span>
            </h1>
            <p class="description" v-html="getMarkdown(quiz.description)"></p>
          </div>
        </div>
        <k-quiz :quizMeta="quiz" :read-only="true"></k-quiz>
      </div>
      <quiz-builder v-else
        v-model:quiz="quiz"
        @update-quiz="getQuiz"
        @files-staged="handleFilesStaged">
      </quiz-builder>
    </div>
    <k-modal @close="closeConfirmationModal" :show="showConfirmationModal" class="confirmation-modal">
      <template #header>
        <h3>
          <i class="fas fa-exclamation-triangle"></i>
          Files Staged for Upload
        </h3>
      </template>
      <template #body>
        <p>You have quiz resources that have not yet been uploaded. These will be lost if you
          enter <b>Preview Mode</b> before updating the quiz.
        </p>
        <p>Do you want to continue?</p>
      </template>
      <template #footer>
        <button class="modal-default-button btn btn-warning" @click="enterRenderMode">
          Continue
        </button>
        <button class="modal-default-button btn btn-default" @click="closeConfirmationModal">
          Return to Quiz
        </button>
      </template>
    </k-modal>
  </div>
</template>

<style scoped>
.controls {
  padding: 5px 15px;
}

h1 > .read-only {
  float: right;
  padding-right: 20px;
  font-size: 0.8em;
  font-style: italic;
  text-transform: lowercase;
}

.heading-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 20px 0 !important;
}

.heading-container .heading-details {
  flex: 1 1 20%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  text-align: right;
}

.title-container {
  align-self: unset;
}

#content .title-container h1 {
  margin: 0 !important;
  align-self: unset;
  padding: 10px 0;
  width: 100%;
}

</style>

<style>
.modal-mask.confirmation-modal > .modal-container,
.modal-mask.confirmation-modal > .modal-container > .modal-body {
  min-height: unset;
}
</style>

<script>
import KModal from '@base-components/k-modal.vue';
import { markdown } from '@utils/maths-markdown';
import QuizBuilder from './quiz_creation/quiz-builder.vue';
import KQuiz from '../../../../quizzes/k-quiz.vue';

export default {
  components: {
    QuizBuilder,
    KQuiz,
    KModal,
  },

  data() {
    return {
      quizReady: false,
      quiz: undefined,
      renderMode: false,
      hasStagedFiles: false,
      showConfirmationModal: false,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getQuiz();
  },

  watch: {
    quizReady() {
      if (this.quizReady) {
        this.$Loading.finish();
      } else {
        this.$Loading.start();
      }
    },
  },

  computed: {
    quizId() {
      return parseInt(this.$route.params.quizId, 10);
    },
  },

  methods: {
    registerCrumbs() {
      this.$crumbs.register([
        {
          text: 'Quiz Overview',
          path: {
            name: 'curriculum_assets_quiz',
          },
        },
        {
          text: this.quiz.name || this.quiz.id,
          active: true,
        },
      ]);
    },

    closeConfirmationModal() {
      this.showConfirmationModal = false;
    },
    enterEditMode() {
      this.renderMode = false;
    },
    enterRenderMode() {
      if (this.hasStagedFiles && !this.showConfirmationModal) {
        this.showConfirmationModal = true;
      } else {
        this.renderMode = true;
        this.showConfirmationModal = false;
      }
    },
    getQuiz() {
      this.quizReady = false;
      this.$logger.info('Getting quiz', { quizId: this.quizId }, true);
      this.$http.get(`/api/curriculum/admin/quiz/${this.quizId}`).then(response => {
        this.quiz = response.data.quiz;
        this.$logger.info('Got quiz', { quizId: this.quizId });
        this.registerCrumbs();
      }).catch(err => {
        if (this.$http.errIn(err, [404])) {
          this.$logger.warn('Quiz not found', { quizId: this.quizId }, err);
        } else if (this.$http.isWarning(err)) {
          this.$logger.warn('Error getting quiz', { quizId: this.quizId }, err);
          this.showError(err, true);
        } else {
          this.$logger.error('Error getting quiz', { quizId: this.quizId }, err);
          this.showError(err, true);
        }
      }).then(() => {
        this.quizReady = true;
      });
    },

    getMarkdown(str) {
      return markdown(str);
    },

    handleFilesStaged(payload) {
      this.hasStagedFiles = payload;
    },
  },
};
</script>
