<template>
  <div>
    <form class="blueprint-release pad-below">
      <fieldset class="form-entry">
        <label for="blueprint-release-input"><i class="fas fa-thumbtack"></i>Release after:</label>
        <input id="blueprint-release-input" v-model="releaseAfterDays" type="number" placeholder="(days)" class="form-control">
      </fieldset>
      <fieldset class="form-entry">
        <label for="blueprint-completion-input"><i class="fas fa-check"></i>Completion time:</label>
        <input id="blueprint-completion-input" v-model="expectedCompletionDays" type="number" placeholder="(days)" class="form-control">
      </fieldset>
    </form>
    <badge-editor
      :badgeOptions="badgeOptions"
      :badgesSortedByThreshold="badgesSortedByThreshold"
      @remove="onRemoveBadge"
      @onAddBadge="onAddBadge"
    />
  </div>
</template>
<style scoped>
form.blueprint-release {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 1em;
}

form.blueprint-release fieldset {
  max-width: 50%;
}

form.blueprint-release fieldset i.fa-thumbtack {
  color: var(--kate-lu);
}

form.blueprint-release fieldset i.fa-check {
  color: var(--kate-success);
}

form.blueprint-release fieldset i {
  margin-right: 8px;
}

.fieldset {
  display: inherit;
}

.pad-below {
  padding-bottom: 1em;
}

.add-badges {
  margin-bottom: 10px;
}

.badges {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
  align-items: center;
}
</style>
<script>

import useReleaseModuleStore from '@stores/release-module-store';
import { sortObjectArray } from '@utils/sort-by-object-property';
import BadgeEditor from './badge-editor.vue';

export default {
  components: {
    BadgeEditor,
  },

  props: {
    module: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      releaseModuleStore: useReleaseModuleStore(),
    };
  },

  computed: {
    releaseAfterDays: {
      get() {
        return this.releaseModuleStore.getModuleValue(this.module.index, 'release_after_days');
      },
      set(newVal) {
        this.releaseModuleStore.updateModuleProperty(this.module.index, 'release_after_days', newVal);
      },
    },
    expectedCompletionDays: {
      get() {
        return this.releaseModuleStore.getModuleValue(this.module.index, 'expected_completion_days');
      },
      set(newVal) {
        this.releaseModuleStore.updateModuleProperty(this.module.index, 'expected_completion_days', newVal);
      },
    },
    badgeOptions() {
      // isaddedmodule then take active ones
      return this.module.programmeBlueprintBadges?.filter(badge => !this.releaseModuleStore.getModuleProperty(this.module.index, 'badges_in_module')?.map(eb => eb.id).includes(badge.id)) || [];
    },
    badgesSortedByThreshold() {
      return sortObjectArray(this.releaseModuleStore.getModuleProperty(this.module.index, 'badges_in_module') ?? this.module?.badges_in_module);
    },
  },

  methods: {
    onAddBadge(badge) {
      this.releaseModuleStore.addBadgeToModule(this.module.index, badge);
    },
    onRemoveBadge(badgeId) {
      this.releaseModuleStore.removeBadgeFromModule(this.module.index, badgeId);
    },
  },
};
</script>
