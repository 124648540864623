<template>
  <section class="panel">
    <div class="panel-body">
      <div class="section-header">
        <h3>Content</h3>
        <hr>
      </div>
      <div class="section-content row">
        <div class="section-item col-md-3 col-sm-6 col-xs-6">
          <div class="section-icon">
            <span class="video-icon">
              <i class="fas fa-film"></i>
            </span>
          </div>
          <div class="section-stats">
            <span>Videos</span>
            <span class="item-stats">{{ videos.length }}</span>
            <button @click="toggleVideosDetails" class="expand-content" :class="[showVideosDetails ? 'active' : '', videos.length <= 0 ? 'disabled' : '']">Expand<i class="fas fa-angle-down"></i></button>
          </div>
        </div>

        <div class="section-item col-md-3 col-sm-6 col-xs-6">
          <div class="section-icon">
            <span class="pak-icon">
              <i class="fas fa-laptop-code"></i>
            </span>
          </div>
          <div class="section-stats">
            <span>Assignments</span>
            <span class="item-stats">{{ paks.length }}</span>
            <button @click="togglePaksDetails" class="expand-content" :class="[showPaksDetails ? 'active' : '', paks.length <= 0 ? 'disabled' : '']" >Expand<i class="fas fa-angle-down"></i></button>
          </div>
        </div>

        <div class="section-item col-md-3 col-sm-6 col-xs-6">
          <div class="section-icon">
            <span class="quiz-icon">
              <i class="fas fa-lightbulb"></i>
            </span>
          </div>
          <div class="section-stats">
            <span>Quizzes</span>
            <span class="item-stats">{{ quizzes.length }}</span> <!-- // jscpd:ignore-start -->
            <button @click="toggleQuizzesDetails" class="expand-content" :class="[showQuizzesDetails ? 'active' : '', quizzes.length <= 0 ? 'disabled' : '']">Expand<i class="fas fa-angle-down"></i></button>
          </div>
        </div>
        <div class="section-item col-md-3 col-sm-6 col-xs-6">
          <div class="section-icon"> <!-- // jscpd:ignore-end -->
            <span class="lu-icon">
              <i class="fas fa-graduation-cap"></i>
            </span>
          </div>
          <div class="section-stats">
            <span>Learning Units</span>
            <span class="item-stats">{{ lus.length }}</span>
            <button @click="toggleLusDetails" class="expand-content" :class="[showLusDetails ? 'active' : '', lus.length <= 0 ? 'disabled' : '']">Expand<i class="fas fa-angle-down"></i></button>
          </div>
        </div>
      </div>
      <div v-if="showVideosDetails || showPaksDetails || showQuizzesDetails || showLusDetails" class="collapsed-content">
        <k-table
          :headers="kableHeaders"
          :rows="kableRows"
          :max="10"
          :hideable="false"
          :panel="false"
          @clicked="tableCallback">
        </k-table>
      </div>
    </div>
    <preview-video :videoId="previewVideoId"
      @close="closePreviewModal">
    </preview-video>
  </section>
</template>

<style scoped>
.section-filter {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 15px;
}
</style>

<style>
.section-filter .switch {
  width: 50px;
  height: 25px;
}

.section-filter .slider::before {
  height: 21px;
  width: 21px;
  left: 2px;
  bottom: 2px;
}

.section-filter .switch input:checked + .slider::before {
  transform: translateX(25px);
}

</style>
<script>
import KTable from '@base-components/k-table.vue';
import { sortObjectArray } from '@utils/sort-by-object-property';
import PreviewVideo from '../../curriculum/assets/videos/preview-video.vue';

export default {
  components: {
    'k-table': KTable,
    'preview-video': PreviewVideo,
  },

  props: {
    programmeId: {
      type: Number,
    },
    videos: {
      type: Array,
      default: () => [],
    },
    paks: {
      type: Array,
    },
    quizzes: {
      type: Array,
      default: () => [],
    },
    lus: {
      type: Array,
    },
  },

  data() {
    return {
      filterByRelease: true,
      showVideosDetails: false,
      showPaksDetails: false,
      showQuizzesDetails: false,
      showLusDetails: false,
      expandDetails: false,
      previewVideoId: undefined,
    };
  },

  computed: {
    sortedVideos() {
      return sortObjectArray(this.videos.slice(), 'date_uploaded', true);
    },

    kableHeaders() {
      // If videos
      if (this.showVideosDetails) {
        return {
          preview: {
            name: 'Preview',
            type: 'action',
          },
          title: {
            name: 'Title',
            filterable: true,
            sortable: true,
          },
          description: {
            name: 'Description',
          },
          module: {
            name: 'Module name',
            filterable: true,
            sortable: true,
          },
          date_recorded: {
            name: 'Recorded',
            sortable: true,
            type: 'date',
          },
          date_uploaded: {
            name: 'Uploaded',
            sortable: true,
            type: 'timestamp',
          },
          displayTags: {
            name: 'Tags',
            type: 'tags',
            filterable: true,
            sortable: true,
          },
        };
      }
      // If quizzes
      if (this.showQuizzesDetails) {
        return {
          name: {
            name: 'Title',
            filterable: true,
            sortable: true,
          },
          description: {
            name: 'Description',
            filterable: true,
          },
          module: {
            name: 'Module name',
            filterable: true,
            sortable: true,
          },
        };
      }
      // If Lus
      if (this.showLusDetails) {
        return {
          name: {
            name: 'Title',
            filterable: true,
          },
          description: {
            name: 'Learning outcome',
          },
          module: {
            name: 'Module',
            filterable: true,
            sortable: true,
          },
        };
      }
      // If Assignments
      return {
        title: {
          name: 'Title',
          filterable: true,
          sortable: true,
        },
        module: {
          name: 'Module name',
          filterable: true,
          sortable: true,
        },
        start_date: {
          name: 'Start date',
          sortable: true,
          type: 'date',
        },
        due_date: {
          name: 'Due date',
          sortable: true,
          type: 'date',
        },
        mandatory: {
          name: 'Mandatory',
          sortable: true,
        },
      };
    },

    kableRows() {
      if (this.showVideosDetails) {
        return this.sortedVideos.map((val, index) => ({
          preview: {
            key: index,
            text: this.previewIcon(),
          },
          module: this.getReleaseSummaryModule(val.release_summary),
          displayTags: {
            tags: val.tags || [],
            sortBy: val.tags ? [...val.tags].sort().join(' ') : '',
          },
          date_recorded: val.date_recorded,
          date_uploaded: val.date_recorded,
          ...val,
        }));
      }
      if (this.showQuizzesDetails) {
        return this.quizzes.map(val => ({
          name: val.name,
          description: val.description,
          module: this.getReleaseSummaryModule(val.release_summary),
        }));
      }
      if (this.showLusDetails) {
        return this.lus.map(val => ({
          name: val.lu_name,
          description: val.description,
          module: val.module_name,
          ...val,
        }));
      }
      return this.paks.map(val => ({
        title: val.name,
        module: val.module_name,
        start_date: val.start_date,
        due_date: val.end_date,
        mandatory: this.getMandatoryTag(val.is_mandatory),
        ...val,
      }));
    },
  },

  methods: {
    previewIcon() {
      return '<div style="text-align: center; color: #5cfff3; font-size: 1.4em;"><i class="fas fa-play-circle"></i></div>';
    },

    getReleaseSummaryModule(releaseSummary) {
      let module = '';
      if (releaseSummary && releaseSummary.length > 0) {
        for (let i = 0; i < releaseSummary.length; i++) {
          if (releaseSummary[i].programme_id === this.programmeId) {
            for (let j = 0; j < releaseSummary[i].modules.length; j++) {
              module = releaseSummary[i].modules[j].name;
            }
          }
        }
      }
      return module;
    },

    tableCallback(key, index, row, header) {
      if (header === 'preview') {
        this.previewVideoId = row.id;
      }
    },

    getMandatoryTag(isMandatory) {
      if (isMandatory === true) {
        return '<div class="tags"><span class="tag-mandatory">mandatory</span></div>';
      }
      if (isMandatory === false) {
        return '<div class="tags"><span class="tag-optional">optional</span></div>';
      }
      return '<small>N/A</small>';
    },

    // Toggles and Expands
    closePreviewModal() {
      this.previewVideoId = undefined;
    },

    toggleVideosDetails() {
      if (this.videos.length > 0) {
        this.showVideosDetails = !this.showVideosDetails;
        this.showPaksDetails = false;
        this.showQuizzesDetails = false;
        this.showLusDetails = false;
      }
    },

    togglePaksDetails() {
      if (this.paks.length > 0) {
        this.showPaksDetails = !this.showPaksDetails;
        this.showVideosDetails = false;
        this.showQuizzesDetails = false;
        this.showLusDetails = false;
      }
    },

    toggleQuizzesDetails() {
      if (this.quizzes.length > 0) {
        this.showQuizzesDetails = !this.showQuizzesDetails;
        this.showVideosDetails = false;
        this.showPaksDetails = false;
        this.showLusDetails = false;
      }
    },

    toggleLusDetails() {
      if (this.lus.length > 0) {
        this.showLusDetails = !this.showLusDetails;
        this.showVideosDetails = false;
        this.showPaksDetails = false;
        this.showQuizzesDetails = false;
      }
    },

  },
};
</script>
